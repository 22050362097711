import React from 'react';
import { graphql } from 'gatsby';

import * as PAGES from '../constants/pages';
import Layout from '../components/Layout';
import Blog from '../components/Blog';

import { container } from './category.module.scss';

export default function CategoryPage({
  location,
  data: { posts },
  pageContext: { pagination, label, prefix, suffix },
}) {
  return (
    <Layout location={location} page={PAGES.CATEGORY}>
      {() => (
        <div className={container}>
          <Blog
            featuredPost={posts.edges[0]}
            posts={posts.edges.slice(1)}
            pagination={pagination}
            header={{
              prefix,
              suffix,
              label,
            }}
          />
        </div>
      )}
    </Layout>
  );
}

export const query = graphql`
  query Category($page: [String]) {
    posts: allMdx(
      filter: { id: { in: $page } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 350)
          frontmatter {
            title
            banner {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          parent {
            ... on File {
              relativePath
            }
          }
        }
      }
    }
  }
`;
